import {useState, useEffect} from 'react';
import centroid from '@turf/centroid';
import PropTypes from 'prop-types';
import {DeckGlMap, PresentationMap} from '@gisatcz/ptr-maps';
import MapSetControls from '../../../common/maps/MapSetControls';
import MapSetInfo from '../../../common/maps/MapSetInfo';
import backgroundLayers from '../../../../data/backgroundLayers';
import CityInfoPanel from './CityInfoPanel';
import PanelControlBtn from './CityInfoPanel/PanelControlBtn';

import './style.scss';

const Tooltip = ({vector}) => {
	return (
		<div className="cure-CityDemonstrations-Tooltip">
			{vector?.[0]?.object?.properties?.name}
		</div>
	);
};

Tooltip.propTypes = {
	vector: PropTypes.array,
};

const shapeStyle = {
	rules: [
		{
			styles: [
				{
					size: 20,
					shape: 'circle',
					outlineWidth: 1,
					outlineColor: '#5f5f5f',
					fill: 'white',
				},
			],
		},
	],
};

const cities = {
	key: 'cities',
	type: 'vector',
	options: {
		style: shapeStyle,
		selectable: true,
		hoverable: true,
		hovered: {
			style: 'default',
		},
		selected: {
			cityDemonstrations: {
				style: {
					size: 30,
					outlineWidth: 2,
					outlineColor: '#ffffff',
					fill: '#10b299',
				},
				keys: [],
			},
		},
		pointAsMarker: true,
		fidColumnName: 'id',
	},
};
const layers = [cities];

const getFeatures = places => {
	return (
		places &&
		places.map(place => {
			const centroidGeom = centroid(place.data.geometry);
			return {
				id: place.key,
				type: 'Feature',
				geometry: centroidGeom.geometry,
				properties: {
					name: place.data.nameDisplay,
				},
			};
		})
	);
};

const CityDemonstrations = ({onMount, onUnmount, places, onPanelControl}) => {
	const [mapLayers, setLayers] = useState(layers);
	const [selectedCityKey, setSelectedCityKey] = useState(null);

	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
			onPanelControl(true);
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	useEffect(() => {
		if (places?.length) {
			const updatedLayer = {
				...cities,
				options: {
					...cities.options,
					features: getFeatures(places),
				},
			};
			setLayers([updatedLayer]);
		}
	}, [places]);

	const onCitySelect = (mapKey, layerKey, featureKeys, hover) => {
		onPanelControl(true);
		const updatedLayer = {
			...cities,
			options: {
				...cities.options,
				features: getFeatures(places),
				selected: {
					...cities.options.selected,
					cityDemonstrations: {
						...cities.options.selected.cityDemonstrations,
						keys: featureKeys,
					},
				},
			},
		};
		hover == 'active' ? null : setSelectedCityKey(featureKeys[0]);
		setLayers([updatedLayer]);
	};

	return (
		<div className="cure-CityDemonstrations">
			<CityInfoPanel
				cityKey={selectedCityKey}
				onCitySelect={onCitySelect}
				places={places}
			/>
			<PanelControlBtn />
			<div className="cure-CityDemonstration-map">
				<PresentationMap
					mapKey="city-demonstration"
					className="city-demonstration"
					mapComponent={DeckGlMap}
					backgroundLayer={backgroundLayers.esri_WorldImagery}
					layers={mapLayers}
					onLayerClick={onCitySelect}
					Tooltip={Tooltip}
					view={{
						center: {
							lat: 48,
							lon: 4,
						},
						boxRange: 3000000,
					}}
				>
					<MapSetControls withoutCompare />
					<MapSetInfo backgroundLayer={backgroundLayers.esri_WorldImagery} />
				</PresentationMap>
			</div>
		</div>
	);
};

CityDemonstrations.propTypes = {
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	places: PropTypes.array,
	onPanelControl: PropTypes.func,
};

export default CityDemonstrations;
